<script lang="ts" setup>
import { Dayjs } from "dayjs";

const { t } = useT();

const props = defineProps<{
	timeLeft?: string | number | Dayjs;
}>();

const isGuest = useIsGuest();

const { durationLeft: promotionTime } = useCountdown({
	timestamp: (props.timeLeft as string) || "",
	formatToken: "DD[D ] HH[H ]mm[M ]",
	isOptimized: true
});
</script>

<template>
	<AText as="div" :modifiers="['uppercase', 'center']" class="timer">
		<template v-if="isGuest">{{ t("Register to Play") }}</template>
		<template v-else>
			{{ t("ends in:") }}&nbsp;<AText :modifiers="['medium']">{{ promotionTime }}</AText>
		</template>
	</AText>
</template>

<style scoped lang="scss">
.timer {
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 22px;
}
</style>
